@use "../config" as *;
.waf-listing {
    .waf-head .title {
        @extend %neutral-50;
    }
    .layout-wrapper {
        position: relative;
    }
    .article-item {
        @extend %article-item-scale;
    }
    .article-thumbnail {
        position: relative;
    }
    .article-info {
        @extend %neutral-1000-bg-6;
        @extend %primary-500;
        @extend %font-10-pb;
        @extend %px-1;
    }
    .item-type-video .item-type-icon {
        z-index: map-get($map: $zindex, $key: default);
        box-shadow: 0 0 0 .8rem clr(neutral-50, 2), 0 0 0 1.5rem clr(neutral-50, 2);
        @extend %rounded-radius;
        @extend %flex;
        @extend %neutral-50-bg-3;
        @extend %font-0;
        &::before {
            font-size: 1.6rem;
            @extend %primary-500;
        }
    }
    .first-list {
        --_fontsize: 16;
        gap: var(--space-3);
        flex-wrap: wrap;
        @include listing-card(overlay);
        @extend %mb-3;
        @extend %flex;
        .article-content {
            @extend %pt-0;
            .article-meta {
                .meta-category {
                    bottom: calc(100% + 1rem);
                    @extend %absolute;
                    @extend %primary-500;
                }
            }
        }
        .item-type-video .item-type-icon {
            top: 38%;
        }
    }
    .second-list {
        --_fontsize: 14;
        // --_line-height: 2.5;
        @include grid(1, var(--space-3));
        @include listing-card(vertical);
        .img-box::after {
            content: "";
            top: 0;
            left: 0;
            background: clr(neutral-1000, 4);
            pointer-events: none;
            transition: background 500ms ease-in-out;
            @extend %h-100;
            @extend %w-100;
            @extend %absolute;
        }
        .article-item:hover {
            .img-box::after {
                background: unset;
            }
        }
        .article-content {
            @extend %pt-9;
            .meta-category {
                bottom: calc(100% - 3.5rem);
                @extend %absolute;
            }
        }
    }
    .article-title {
        font-weight: 500;
        @extend %mb-5;
    }
    .article-content {
        @extend %p-3;
        .reaction-section {
            @extend %mt-0;
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-listing {
        .first-list {
            --_fontsize: 20;
            flex-wrap: nowrap;
            .item-type-video .item-type-icon {
                box-shadow: 0 0 0 1.2rem clr(neutral-50, 2), 0 0 0 2rem clr(neutral-50, 2);
                &::before {
                    font-size: 2rem;
                }
            }
        }
        .second-list {
            --_fontsize: 16;
            @include grid(3, var(--space-3));
        }
    }
}